<template>
  <div class="home">
    <h3 class="common-title">多语言设置</h3>
    <div class="home-left">
      <el-container>
        <el-main>
          <div v-loading="loading" class="p20">
            <el-row v-for="language in serverLanguages" class="mt10">
              <el-col :span="8">
                <div> {{ language.name }}</div>
              </el-col>
              <el-col :span="5">
                <el-checkbox :label="language.id" :key="language.id" v-model="language.is_active"
                             :checked="language.is_active" @change="onLanguagesCheckboxChange($event, language)">启用
                </el-checkbox>
              </el-col>
              <el-col :span="5">
                <el-checkbox :label="language.id" :key="language.id" v-model="language.is_published"
                             :checked="language.is_published" :disabled="!language.is_active"
                             @change="onLanguagesPublishedCheckboxChange($event, language)">
                  公开
                </el-checkbox>
              </el-col>
              <el-col :span="6">
                <el-button type="primary" @click="checkMerchantMultilingual(language)">公开检查
                </el-button>
              </el-col>
            </el-row>
          </div>
          <div v-if="multilingualForm.messages.length>0" class="p15 mt5" style="background-color: khaki">
            <el-row v-for="message in multilingualForm.messages" class="cRed">
              * {{ message }}
            </el-row>
            <div class="mt10 cRed">由于以上原因,多语言信息无法公开,请补充后重试。</div>
          </div>
        </el-main>
        <el-footer>
          <div class="clearfix pb20 dialog-footer">
            <div class="fr">
              <el-button type="primary" @click="updateMerchantMultilingual">确定</el-button>
            </div>
          </div>
        </el-footer>
      </el-container>
    </div>

  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { authApi } from '@/api'
import { computed, onMounted } from "@vue/runtime-core";
import Empty from "@/components/Empty.vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
// @ is an alias to /src

export default {
  name: 'Home',
  components: { Empty },
  setup() {
    const route = useRoute()
    const router = useRouter()

    // merchant form

    const merchant = computed(() => {
      return store.getters.merchant
    })

    const loading = ref(false)

    const serverLanguages = ref([])

    const getMultilingual = () => {
      loading.value = true
      authApi.getMerchantMultilingual(merchant.value.id).then(res => {
        multilingualForm.languages = res.data.filter(item => item.is_active).map(item => item.id)
        multilingualForm.published = res.data.filter(item => item.is_published).map(item => item.id)
        multilingualForm.messages = []
        serverLanguages.value = res.data
        console.log(res)
      }).finally(() => {
        loading.value = false
      })
    }

    const onLanguagesCheckboxChange = (value, language) => {
      if (value) {
        if (multilingualForm.languages.includes(language.id)) {
          return
        }
        multilingualForm.languages.push(language.id)
      } else {
        multilingualForm.languages = multilingualForm.languages.filter(item => item !== language.id)
      }
    }

    const onLanguagesPublishedCheckboxChange = (value, language) => {
      if (value) {
        if (multilingualForm.published.includes(language.id)) {
          return
        }
        ElMessageBox.confirm(
          '确定要公开吗?',
          '多语言公开',
          {
            confirmButtonText: '公开',
            cancelButtonText: '取消',
            type: 'error',
          }).then(() => {
          const form = {
            languages: [language.id],
            published: [language.id],
          }
          authApi.checkMerchantMultilingual(merchant.value.id, form).then(res => {
            multilingualForm.messages = res.data
            if (res.data.length === 0) {
              multilingualForm.published.push(language.id)
            } else {
              language.is_published = !language.is_published
            }
          }).finally(() => {
            loading.value = false
          })
        }).catch(() => {
          language.is_published = !language.is_published
        })

      } else {
        multilingualForm.published = multilingualForm.published.filter(item => item !== language.id)
      }
    }


    // 多语言

    const multilingualForm = reactive({
      languages: [],
      published: [],
      messages: [],
    })

    const multilingualRuleFormRef = ref(null)

    const updateMerchantMultilingual = () => {
      authApi.updateMerchantMultilingual(merchant.value.id, multilingualForm).then(res => {
        console.log(res)
        ElMessage({
          message: '编辑成功',
          type: 'success',
        })
        authApi.getMerchantDetail(merchant.value.id).then(res => {
          store.commit('setMerchant', res.data)
        }).finally(() => {
          loading.value = false
        })
        getMultilingual()
      })
    }

    const checkMerchantMultilingual = (language) => {
      const form = {
        languages: [language.id],
        published: [language.id],
      }
      authApi.checkMerchantMultilingual(merchant.value.id, form).then(res => {
        console.log(res)
        multilingualForm.messages = res.data
        if (res.data.length === 0) {
          ElMessage.success('检查通过,可以公开.')
        }
      }).finally(() => {
        loading.value = false
      })
    }

    onMounted(() => {
      getMultilingual()
    })
    return {
      loading,

      merchant,

      onLanguagesCheckboxChange,

      // 多语言
      serverLanguages,
      multilingualForm,
      multilingualRuleFormRef,
      updateMerchantMultilingual,
      onLanguagesPublishedCheckboxChange,
      checkMerchantMultilingual,
    }
  }
}
</script>
<style scoped>
.preview-cell {
  white-space: pre-line;
}

.document-tabs .el-tabs__item {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
</style>
